<template>
	<div class="page">
		<el-form class="form_ordinary" label-width="100px">

			<div class="big_tit">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="司机电话" style="width:33.5%">
					<el-input class="el_inner_width" v-model="driver_tel" clearable>
						<el-button type="success" slot="append" @click="app_user_ser">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机姓名" style="width:31.5%;margin-left:1%">
					<el-input class="el_inner_width" v-model="user_info.name" :disabled="user_info.real_status==2" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="身份证号" style="width:32.5%;margin-left:1%">
					<el-input class="el_inner_width" v-model="user_info.id_card_num" :disabled="user_info.real_status==2" clearable></el-input>
				</el-form-item>
			</div>
			<div class="big_tit">头像/身份证</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="实名状态" style="width:100%">
					<el-input class="el_inner_width" v-model="user_info.real_status_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:32%">
					<div class="img_btn" @click="choose_img('headimg')">
						<div v-if="!headimg.src" class="btn">未上传</div>
						<img  v-else :src="headimg.src" class="img">
					</div>
					<div class="bottom_text">头像</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn" @click="choose_img('id_card_img1')">
						<div v-if="!id_card_img1.src" class="btn">未上传</div>
						<img  v-else :src="id_card_img1.src" class="img">
					</div>
					<div class="bottom_text">身份证正面</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn" @click="choose_img('id_card_img2')">
						<div v-if="!id_card_img2.src" class="btn">未上传</div>
						<img  v-else :src="id_card_img2.src" class="img">
					</div>
					<div class="bottom_text">身份证反面</div>
				</div>
			</div>


			<div class="big_tit">司机证件</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="司机状态" style="width:100%">
					<el-input class="el_inner_width" v-model="cert_info.status_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:49%">
					<div class="img_btn" @click="choose_img('dl_img')">
						<div v-if="!dl_img.src" class="btn">未上传</div>
						<img  v-else :src="dl_img.src" class="img">
					</div>
					<div class="bottom_text">驾驶证</div>
				</div>
				<div class="el_form_item" style="width:49%;margin-left:1%">
					<div class="img_btn" @click="choose_img('cy_img')">
						<div v-if="!cy_img.src" class="btn">未上传</div>
						<img  v-else :src="cy_img.src" class="img">
					</div>
					<div class="bottom_text">从业资格证</div>
				</div>
			</div>

		</el-form>

		<div class="bottom_btns" v-if="!(user_info.real_status==2&&cert_info.status==2)&&has_ser">
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>

	</div>
</template>
<script>
	export default {
		comments:{
			
		},
		data() {
			return {

				//司机电话
				driver_tel:'',
				driver_tel_lock:'',

				user_num:'',//用户编号

				has_ser:false,//是否已经查找过

				//用户信息
				user_info:{
					real_status:'',//实名状态
					real_status_text:'',//实名状态字符串
					name:'',//司机姓名
					id_card_num:'',//审核失败原因
				},

				//司机证件信息
				cert_info:{
					status:'',//司机证件状态
					status_text:'',//司机证件状态字符串
				},

				//头像
				headimg:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'headimg'
				},

				//身份证正面
				id_card_img1:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'idcard1'
				},

				//身份证反面
				id_card_img2:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'idcard2'
				},

				//驾驶证
				dl_img:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'driverdl'
				},

				//从业资格证
				cy_img:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'drivercy'
				},

				//提交锁
				sub_swh:false
			}
		},
		created(){
			
			//初始化
			this.init();
		},
		methods: {

			//提交
			sub(){

				if(!this.has_ser){
					return;
				}

				if(this.sub_swh){
					this.$my.other.msg({
						type:'warning',
						str:'提交中...'
					});
					//return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'添加此司机",
					confirm:()=>{

						//用户数据处理
						if(!this.user_info.name.trim()){
							this.$my.other.msg({
								type:'warning',
								str:'司机姓名不能为空'
							});
							return;
						}
						if(this.user_info.name.trim()=="未实名"){
							this.$my.other.msg({
								type:'warning',
								str:'司机姓名不能为空'
							});
							return;
						}
						if(!this.$my.check.id_card_num(this.user_info.id_card_num)){
							this.$my.other.msg({
								type:'warning',
								str:'司机身份证号不能为空'
							});
							return;
						}
						

						//头像
						if(this.headimg.is_choose){
							
							//上传图片
							this.$my.qiniu.upl({
								bucket: this.headimg.bucket,
								key: this.headimg.key, //xxxxxx?v=1 或者 null
								file_path: this.headimg.src,
								callback: (data) => {
									this.headimg.is_upl = true;
									this.headimg.key = data.key;
									this.do_sub();
								}
							});
						}

						//身份证正面
						if(this.id_card_img1.is_choose){

							//上传图片
							this.$my.qiniu.upl({
								bucket: this.id_card_img1.bucket,
								key: this.id_card_img1.key, //xxxxxx?v=1 或者 null
								file_path: this.id_card_img1.src,
								callback: (data) => {
									this.id_card_img1.is_upl = true;
									this.id_card_img1.key = data.key;
									this.do_sub();
								}
							});
						}

						//身份证背面
						if(this.id_card_img2.is_choose){
							
							//上传图片
							this.$my.qiniu.upl({
								bucket: this.id_card_img2.bucket,
								key: this.id_card_img2.key, //xxxxxx?v=1 或者 null
								file_path: this.id_card_img2.src,
								callback: (data) => {
									this.id_card_img2.is_upl = true;
									this.id_card_img2.key = data.key;
									this.do_sub();
								}
							});
						}

						//驾驶证
						if(this.dl_img.is_choose){
							
							//上传图片
							this.$my.qiniu.upl({
								bucket: this.dl_img.bucket,
								key: this.dl_img.key, //xxxxxx?v=1 或者 null
								file_path: this.dl_img.src,
								callback: (data) => {
									this.dl_img.is_upl = true;
									this.dl_img.key = data.key;
									this.do_sub();
								}
							});
						}

						//从业资格证
						if(this.cy_img.is_choose){
							
							//上传图片
							this.$my.qiniu.upl({
								bucket: this.cy_img.bucket,
								key: this.cy_img.key, //xxxxxx?v=1 或者 null
								file_path: this.cy_img.src,
								callback: (data) => {
									this.cy_img.is_upl = true;
									this.cy_img.key = data.key;
									this.do_sub();
								}
							});
						}

						//用于补充
						this.do_sub();
					}
				});
			},

			//执行提交
			do_sub(){

				//头像
				if(!this.headimg.is_upl&&this.headimg.is_choose)return;

				//身份证正面
				if(!this.id_card_img1.is_upl&&this.id_card_img1.is_choose)return;

				//头像
				if(!this.id_card_img2.is_upl&&this.id_card_img2.is_choose)return;

				//头像
				if(!this.dl_img.is_upl&&this.dl_img.is_choose)return;

				//头像
				if(!this.cy_img.is_upl&&this.cy_img.is_choose)return;

				if(this.sub_swh){
					return;
				}

				//上锁
				this.sub_swh=true

				//调接口
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_creat_by_admin',
						tel:this.driver_tel_lock,
						user_num:this.user_num,
						user_info:{
							name:this.user_info.name,
							id_card_num:this.user_info.id_card_num,
							headimg:this.headimg.key,
							id_card_img1:this.id_card_img1.key,
							id_card_img2:this.id_card_img2.key,
						},
						cert_info:{
							dl_img:this.dl_img.key,
							cy_img:this.cy_img.key,
						},
					},
					callback:(data)=>{
						
						//报告结果
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						//表单初始化
						this.init();
					}
				});
			},

			//选择图片
			choose_img(img_name) {

				//未搜索不能修改
				if(!this.has_ser){
					return;
				}

				//头像和身份证
				if(['headimg','id_card_img1','id_card_img2'].indexOf(img_name)!=-1){

					//已实名的话不能修改
					if(this.user_info.real_status==2){
						return;
					}
				}

				//驾驶证和从业资格证
				if(['dl_img','cy_img'].indexOf(img_name)!=-1){

					//已实名的话不能修改
					if(this.cert_info.status==2){
						return;
					}
				}

				//取出相应对象
				let ctr_obj = this[img_name]

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
						ctr_obj.src = res.src;
						ctr_obj.is_choose = true;
						ctr_obj.is_upl = false;
					}
				});
			},

			//用户查找
			app_user_ser(){

				if(!this.$my.check.is_tel(this.driver_tel)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少司机电话'
					});
					return;
				}

				//锁定手机号码
				this.driver_tel_lock=this.driver_tel

				//解除提交锁
				this.sub_swh=false

				//读取用户信息
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'admin_simple_list',
						tel:this.driver_tel,
					},
					callback:(data)=>{

						//已搜索
						this.has_ser=true;

						//取出用户数据
						let user_info=data.list[0]?data.list[0]:[];

						//用户编号
						this.user_num=user_info.user_num

						//基本数据
						this.user_info.real_status=user_info.real_status?user_info.real_status:'0'
						if(user_info.name){
							this.user_info.name=user_info.name
						}
						if(user_info.id_card_num){
							this.user_info.id_card_num=user_info.id_card_num
						}
						/*
						this.user_info.name=user_info.name?user_info.name:''
						this.user_info.id_card_num=user_info.id_card_num?user_info.id_card_num:''
						*/

						//实名状态字符串
						switch(user_info.real_status){
							case '1':this.user_info.real_status_text="未实名";break;
							case '2':this.user_info.real_status_text="实名成功";break;
							case '3':this.user_info.real_status_text="实名失败";break;
							default :this.user_info.real_status_text="未注册";
						}
						
						//头像
						if(user_info.headimg){
							this.headimg.key=user_info.headimg
							this.headimg.src=this.$my.qiniu.make_src(this.headimg.bucket,user_info.headimg)
						}else{
							this.headimg.key=''
							this.headimg.src=''
						}

						//身份证正面
						if(user_info.id_card_img1){
							this.id_card_img1.key=user_info.id_card_img1
							this.id_card_img1.src=this.$my.qiniu.make_src(this.id_card_img1.bucket,user_info.id_card_img1)
						}else{
							this.id_card_img1.key=''
							this.id_card_img1.src=''
						}

						//身份证反面
						if(user_info.id_card_img2){
							this.id_card_img2.key=user_info.id_card_img2
							this.id_card_img2.src=this.$my.qiniu.make_src(this.id_card_img2.bucket,user_info.id_card_img2)
						}else{
							this.id_card_img2.key=''
							this.id_card_img2.src=''
						}
					}
				});

				//读取司机信息
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_list_by_admin',
						user_tel:this.driver_tel,
					},
					callback:(data)=>{

						//取出司机数据
						let driver_info=data.list[0]?data.list[0]:[];

						//司机证件审核状态
						this.cert_info.status=driver_info.status?driver_info.status:'0'

						//司机证件审核状态字符串
						switch(driver_info.status){
							case '1':this.cert_info.status_text="审核中";break;
							case '2':this.cert_info.status_text="审核通过";break;
							case '3':this.cert_info.status_text="审核未通过";break;
							default :this.cert_info.status_text="未添加";
						}

						//驾驶证
						if(driver_info.dl_img){
							this.dl_img.key=driver_info.dl_img
							this.dl_img.src=this.$my.qiniu.make_src(this.dl_img.bucket,driver_info.dl_img)
						}else{
							this.dl_img.key=''
							this.dl_img.src=''
						}

						//从业资格证
						if(driver_info.cy_img){
							this.cy_img.key=driver_info.cy_img
							this.cy_img.src=this.$my.qiniu.make_src(this.cy_img.bucket,driver_info.cy_img)
						}else{
							this.cy_img.key=''
							this.cy_img.src=''
						}
					}
				});
			},

			//初始化
			init(){

				//手机号码
				this.driver_tel="";

				//锁定手机号码
				this.driver_tel_lock=""

				//解除提交锁
				this.sub_swh=false;

				//未搜索
				this.has_ser=false;

				//用户编号
				this.user_num=""

				//基本数据
				this.user_info.real_status=""
				this.user_info.real_status_text=""
				this.user_info.name=''
				this.user_info.id_card_num=''

				//头像
				this.headimg.key=""
				this.headimg.src=""
				this.headimg.is_choose=false
				this.headimg.is_upl=false

				//身份证正面
				this.id_card_img1.key=""
				this.id_card_img1.src=""
				this.id_card_img1.is_choose=false
				this.id_card_img1.is_upl=false

				//身份证反面
				this.id_card_img2.key=""
				this.id_card_img2.src=""
				this.id_card_img2.is_choose=false
				this.id_card_img2.is_upl=false

				//司机证件审核状态
				this.cert_info.status=''
				this.cert_info.status_text=''

				//驾驶证
				this.dl_img.key=""
				this.dl_img.src=""
				this.dl_img.is_choose=false
				this.dl_img.is_upl=false
				
				//从业资格证
				this.cy_img.key=""
				this.cy_img.src=""
				this.cy_img.is_choose=false
				this.cy_img.is_upl=false
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.page{
		background-color: #fff;
		padding:0 20px 20px 20px;
		border-radius: 10px;
		height: calc(100% - 20px);
		width: 1040px;
		overflow: auto;
		margin: auto;
	}
		
</style>